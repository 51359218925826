import React from "react"
import Header from "../components/pn-header"
import RoutineContent from "../components/routine-content"
import GoogleAnalytics from "../components/analytics"
import ThemeChanger from "../components/themeChanger"
// import '../styles/programNews.css'

const getResizedImg = (node, size) => {
    if (node.frontmatter === undefined) return '';
    let srcSet = node.frontmatter.featuredImage.childImageSharp.fluid.srcSet;
    let imgPath = srcSet.split(',').find(elem => elem.indexOf(size.toString()) !== -1).trim().split(' ')[0] || "";
    return imgPath || '';
}

export default ({ pageContext, data }) => {
    const assetHeader = data.assetHeader.nodes[0];
    const headerImg = getResizedImg(assetHeader, 760);

    let headerTitle;
    if (pageContext.language === 'por') headerTitle = "Novidades da Rotina";
    if (pageContext.language === 'eng') headerTitle = "Program News";
    if (pageContext.language === 'spa') headerTitle = "Noticias de Rutina";

    let imgContext = {
        module: pageContext.module,
        country: pageContext.country,
        language: pageContext.language,
        filename: pageContext.imageInfo.filename,
        fileExtension: pageContext.imageInfo.fileExtension,
        title: pageContext.imageInfo.title,
        text: pageContext.imageInfo.text,
        type: pageContext.imageInfo.type
    }

    return (
        <div style={{textAlign: "center", alignItems: "center", display: "flex", "flex-direction": "column"}}>
            <title>{`${pageContext.module}-${pageContext.country}-${pageContext.language}-${pageContext.imageInfo.name}`}</title>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-K14SPPV5EN"></script>
            <GoogleAnalytics module={pageContext.moduleName} />
            <ThemeChanger />
            <header>
                <Header title={headerTitle} subtitle={pageContext.imageInfo.title} imgPath={headerImg}></Header>
            </header>
            <section className="gifArea">
                <RoutineContent context={imgContext} />
            </section>
        </div>
    )
}

export const query = graphql`
query {
    assetHeader: allMarkdownRemark(filter: { fileAbsolutePath: {regex: "/assets/"}, frontmatter: { type: {eq: "pn-header"}}}) {
        nodes{
            frontmatter {
                type
                featuredImage {
                    childImageSharp {
                    fluid(maxWidth: 760, quality: 80) {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
            }
        }
    }
}
`