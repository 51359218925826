import React from "react"
import { css } from "@emotion/core"

export default (props) => {

    const componentCss = css`
        height: 88px;
        width: 760px;
        margin-bottom: 10px;
    `

    const panelHeaderCss = css`
        background-image: url(${props.imgPath});
        height: 88px;
        width: 760px;
        position: absolute;
    `

    const titleCss = css`
        font-family: 'Lato', sans-serif;
        text-align: center;
        text-transform: uppercase;
        color: white;
        text-shadow: orange 2px 2px;
        position: relative;
        margin-top: 14px;
        margin-bottom: 11px;
    `

    const subtitleCss = css`
        font-family: 'Lato', sans-serif;
        text-align: center;
        color: white;
    `

    return (
        <div css={componentCss}>
            <div css={panelHeaderCss}>
                <b><p css={titleCss}>{props.title}</p></b>
                <p css={subtitleCss}>{props.subtitle}</p>
            </div>
        </div>
    )
}