import React from "react"
import panelStyles from "./content.module.css"
import { css } from "@emotion/core"

const divCss = css`
    align-items: center;
    display: flex;
    flex-direction: column;
`

const imgCss = css`
    height: 405px;
    width: 720px;
    margin-bottom: 0px;
    
`

const textCss = css`
    text-align: justify;
    width: 720px;
`

const RoutineContent = (props) => {
    
    const gifUrl = `https://totvsnews.engpro.totvs.com.br/${props.context.module}/${props.context.country}/${props.context.language}/images/${props.context.filename}.${props.context.fileExtension}`
    console.log(props.context)
    return (
        <div className={panelStyles.pnContent} css={divCss}>
            {props.context.type === "image" && <img src={gifUrl} css={imgCss} alt={props.context.filename} />}
            {props.context.type === "text" && <div css={textCss} dangerouslySetInnerHTML={{ __html: props.context.text}}></div>}
        </div>
    )
}

export default RoutineContent;