import React from "react"
class GoogleAnalytics extends React.Component {
    
    componentDidMount() {
        window.dataLayer = window.dataLayer || []
        function gtag(){window.dataLayer.push(arguments);};
        gtag('js', new Date());
        gtag('config', 'G-K14SPPV5EN');
        gtag('config', 'G-K14SPPV5EN', {'app_name': 'TotvsNews'});
        gtag('event', 'screen_view', { 'screen_name': this.props.module});
    }

    render() {
        return (
            <script type="text/javascript">
            </script>
        );
    }
}

export default GoogleAnalytics;