/* eslint-disable */

import React from "react"
class ThemeChanger extends React.Component {
    
    componentDidMount() {
		var queryParam = new URLSearchParams(window.location.search);
		var theme = queryParam.get('theme');
		document.querySelector(':root').setAttribute('theme', theme);
    }

    render() {
        return (
            <script type="text/javascript">
            </script>
        );
    }
}

export default ThemeChanger;